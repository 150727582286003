<template>
  <div>
    <div class="row d-flex ml-2 mt-8">
      <h4 class="title-card-custom">Role</h4>

      <!-- <div class="float-left ml-5 mb-5">
        <b-button variant="danger"
        ><b-icon-upload></b-icon-upload> Export
        </b-button>
        <b-button variant="warning" class="text-dark ml-5"
        ><b-icon-download></b-icon-download> Import
        </b-button>
      </div> -->
      <div class="justify-content-end d-flex ml-5 mb-5">
        <b-button
          variant="danger"
          @click.prevent.stop="onAdd()"
          class="float-left mt- mb-3"
        >
          Add Role</b-button
        >
      </div>
    </div>
    <div>
      <div
        class="row no-gutters"
        style="display: flex; justify-content: center !important"
      >
        <div style="width: 98%;margin-top:21px">
          <div id="chart" style="min-height: 300px;">
            <DxDataGrid
              :ref="my - data - grid"
              :data-source="itemsuser"
              key-expr="id"
              :show-borders="true"
              :word-wrap-enabled="false"
              :column-auto-width="false"
              :remote-operations="true"
              :column-hiding-enabled="false"
            >
              <DxSearchPanel :visible="true" />
              <DxFilterRow :visible="false" />
              <DxColumn
                data-field="id"
                sort-order="desc"
                caption="ID"
                :width="70"
              />
              <DxColumn data-field="name" caption="Nama Role" />
              <DxColumn
                data-field="updated_at"
                data-type="date"
                format="dd/MM/yyyy"
              />
              <DxColumn :width="100" type="buttons" caption="Aksi">
                <DxButton icon="fa fa-trash" :disabled="isSuperadmin" hint="Hapus" :onClick="hapus" />
                <DxButton icon="fa fa-edit" :disabled="isSuperadmin" hint="Edit" :onClick="iconEdit" />
              </DxColumn>
              <DxPaging :page-size="10" />
              <DxPager
                :visible="true"
                :show-page-size-selector="showPageSizeSelector"
                :allowed-page-sizes="pageSizes"
              />
            </DxDataGrid>
          </div>
        </div>
      </div>
      <b-modal
        v-model="showModalAdd"
        hide-footer
        centered
        scrollable
        size="lg"
        id="add-new-modal"
      >
        <template #modal-header="{ close }" class="text-center">
          <h3>Tambah Role</h3>
        </template>
        <b-form @submit.stop.prevent="onSubmit">
          <b-form-group id="example-input-group-1" label-for="example-input-1">
            <template v-slot:label>
              Role Name<span class="text-danger">*</span>
            </template>
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              placeholder="Nama"
              v-model="$v.form.name.$model"
              :state="validateState('name')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback"
              >Nama Role harus diisi.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group id="example-input-group-1" label-for="example-input-1">
            <template v-slot:label>
              Menu<span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.form.menu.$model"
              :state="validateState('menu')"
              placeholder="Search or add a Menu"
              label="name"
              track-by="id"
              :options="options"
              :multiple="true"
            ></multiselect>

            <b-form-invalid-feedback id="input-1-live-feedback"
              >Menu harus diisi.
            </b-form-invalid-feedback>
          </b-form-group>
          <hr />
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-cancel mr-8"
              type="button"
              @click="onCancel()"
            >
              Kembali
            </button>
            <button type="submit" class="btn btn-add">Add</button>
          </div>
        </b-form>
      </b-modal>

      <!--   Edit Existing User   -->
      <b-modal
        v-model="showModalEdit"
        hide-footer
        scrollable
        centered
        size="lg"
        id="edit-user-modal"
      >
        <template #modal-header="{ close }" class="text-center">
          <h3>Edit Role</h3>
        </template>
        <b-form @submit.stop.prevent="onSubmitEdit">
          <b-form-group id="name-group" label-for="name">
            <template v-slot:label>
              Nama Depan<span class="text-danger">*</span>
            </template>
            <b-form-input
              id="name"
              name="name"
              placeholder="Nama Depan"
              v-model="$v.formEdit.name.$model"
              :state="validateStateFormEdit('name')"
              aria-describedby="name-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="name-feedback"
              >Nama Role harus diisi.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group id="example-input-group-1" label-for="example-input-1">
            <template v-slot:label>
              Menu<span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.formEdit.menu.$model"
              :state="validateStateFormEdit('menu')"
              placeholder="Search or add a Menu"
              label="name"
              track-by="id"
              :options="options"
              :multiple="true"
            ></multiselect>

            <b-form-invalid-feedback id="input-1-live-feedback"
              >Menu harus diisi.
            </b-form-invalid-feedback>
          </b-form-group>
          <hr />
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-cancel mr-8"
              type="button"
              @click="resetFormEdit()"
            >
              Kembali
            </button>
            <button
              type="submit"
              :disabled="$v.formEdit.$invalid"
              class="btn btn-add"
            >
              Edit
            </button>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import WebService from "../../../core/services/api";
import EventBus from "../../../core/services/common/EventBus";
import {
  DxButton,
  DxColumn,
  DxDataGrid,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxSearchPanel
} from "devextreme-vue/data-grid";
import Multiselect from "vue-multiselect";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DxButton,
    DxSearchPanel,
    DxColumn,
    DxDataGrid,
    DxFilterRow,
    DxPager,
    DxPaging,
    Multiselect
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: "",
        menu: ""
      },
      formEdit: {
        name: "",
        menu: ""
      },
      value: [],
      options: [],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      file1: null,
      file2: null,
      showModalAdd: false,
      showModalEdit: false,
      statuses: [
        {
          value: "true",
          text: "Aktif"
        },
        { value: "false", text: "Tidak Aktif" }
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      selected: null,
      optionsmodule: [
        { value: null, text: "Select One" },
        { value: "a", text: "Yes" },
        { value: "b", text: "No" },
        { value: "c", text: "--" },
        { value: "d", text: "--" }
      ],
      // array user
      itemsuser: [],
      roles: [],
      fieldsuser: [
        {
          key: "no",
          label: "No.",
          class: "text-center"
        },
        {
          key: "name",
          label: "Nama depan",
          sortable: true,
          class: "text-center"
        },
        { key: "actions", label: "Actions" }
      ]
    };
  },
  validations: {
    form: {
      name: {
        required
      },
      menu: {
        required
      }
    },
    formEdit: {
      name: {
        required
      },
      menu: {
        required
      }
    }
  },
  mounted() {
    this.getDataTable();
    this.getMenu();
  },
  methods: {
    getMenu() {
      WebService.getMenuRef().then(
        res => {
          this.options = res.data.data;
        },
        error => {
          console.log(error);
          this.options = [];
        }
      );
    },
    getDataTable() {
      WebService.getRoleAll().then(
        res => {
          this.itemsuser = res.data.data;
          this.totalRows = res.data.totalCount;
        },
        error => {
          console.log(error);
          this.itemsuser = [];
          this.totalRows = 0;
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateFormEdit(name) {
      const { $dirty, $error } = this.$v.formEdit[name];
      return $dirty ? !$error : null;
    },
    isSuperadmin(e) {
      return e.row.data.id == 2;
    },
    getLabelCounter() {
      let from = (this.currentPage - 1) * this.perPage + 1;
      let dari =
        this.perPage > this.itemsuser.length
          ? this.currentPage * this.perPage -
            this.perPage +
            this.itemsuser.length
          : this.currentPage * this.perPage;
      return (
        "Shown results " +
        from +
        " - " +
        dari +
        " From " +
        this.totalRows +
        " Data"
      );
    },
    getNumberSequence(index) {
      return index + 1 + this.currentPage * this.perPage - this.perPage;
    },
    hapus(value) {
      console.log(value);
      // this.itemsuser.splice(index, 1);
      const id = value.row.data.id;
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Yakin Data Dihapus?.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "OK",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.deleteRole(id)
            .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil Dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetForm();
              this.getDataTable();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Data Gagal Dihapus",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    hideModal() {
      this.$refs["my-modaledit"].hide();
    },
    onCancel() {
      this.showModalAdd = false;
      this.resetForm();
    },
    onAdd(){
      this.showModalAdd = true;
      this.$v.form.$reset();
      this.resetForm();
    },
    onSubmit() {
      this.$v.form.$touch();
      console.log(this.$v.form.$anyError);
      if (this.$v.form.$anyError) {
        return;
      }
      let menus = [];
      this.form.menu.forEach(items => {
        menus.push(items.id);
      });
      let data = {
        name: this.form.name,
        menus: menus,
        permissions: []
      };

      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.addRole(data)
            .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil ditambah",
                showConfirmButton: false,
                timer: 1500
              });
              this.$v.form.$reset();
              this.resetForm();
              this.getDataTable();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Data yang ditambah sudah ada",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onSubmitEdit() {
      this.$v.formEdit.$touch();
      if (this.$v.formEdit.$anyError) {
        return;
      }

      let id = this.formEdit.id;
      let menus = [];
      this.formEdit.menu.forEach(items => {
        menus.push(items.id);
      });
      let data = {
        id: this.formEdit.id,
        name: this.formEdit.name,
        menus: menus,
        permissions: []
      };
      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.editRole(data, id)
            .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil diubah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetFormEdit();
              this.getDataTable();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal mengubah data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        id: null,
        name: "",
        menu: []
      };
      this.$nextTick(() => {
        this.$bvModal.hide("add-new-modal");
      });
    },
    resetFormEdit() {
      this.formEdit = {
        id: null,
        name: "",
        menu: []
      };

      this.$nextTick(() => {
        this.$bvModal.hide("edit-user-modal");
      });
    },
    iconEdit(e) {
      console.log(e.item);
      this.showModalEdit = true;
      const data = e.row.data;
      this.formEdit.name = data.name;
      this.formEdit.last_name = data.last_name;
      this.formEdit.email = data.email;
      this.formEdit.nip = data.nip;
      this.formEdit.nik = data.nik;
      this.formEdit.no_telp = data.no_telp;
      this.formEdit.status = data.is_active;
      this.formEdit.role = data.role_id;
      this.formEdit.direktorat = data.direktorat_id;
      this.formEdit.id = data.id;
      this.formEdit.menu = data.menus;
      this.setDataModal = data;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.btn-add {
  width: 144px;
  height: 44px;
  background: #c60b09;
  border-radius: 8px;
  color: #fffafa;
}
.multiselect--active {
  z-index: 1000;
}
.btn-cancel {
  width: 144px;
  height: 44px;
  border: 1px solid #c60b09;
  box-sizing: border-box;
  border-radius: 8px;
  color: #c60b09;
}
.title-card-custom {
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: 0.005em;
}
.table-custom {
  border-radius: 15px 15px 15px 15px !important;
}

.thead-block {
  text-align: center;
  background: #d1eefa;
  color: #360302;
  border: none;
}

.thead-block tr th:first-child {
  border-top-left-radius: 15px;
}

.thead-block tr th:last-child {
  border-top-right-radius: 15px;
}

.table-content {
  background: #fafcff;
  text-align: center;
  border-radius: 0 0 15px 15px;
}
</style>
