<template>
  <div>
    <div class="row d-flex ml-2 mt-8">
      <h4>Master Customer</h4>
      <div class="justify-content-end d-flex ml-5 mb-5">
        <b-button
          variant="danger"
          @click.prevent.stop="showModalAdd = true"
          class="float-left mt- mb-3"
        >
          Add Customer</b-button
        >
      </div>
    </div>
    <div>
      <DxDataGrid
        :ref="dataGridRefKey"
        :data-source="dataSource"
        :columns="columns"
        key-expr="id"
        :show-borders="true"
        :word-wrap-enabled="false"
        :column-auto-width="false"
        :remote-operations="true"
        :column-hiding-enabled="false"
      >
        <DxSearchPanel :visible="true" />
        <DxFilterRow :visible="false" />
        <DxColumn data-field="id" sort-order="desc" caption="ID" :width="70" />
        <DxColumn
          data-field="name"
          caption="Nama"
          :filter-operations="['contains']"
        />
        <DxColumn :width="100" type="buttons" caption="Aksi">
          <DxButton icon="fa fa-trash" hint="Hapus" :onClick="hapus" />
          <DxButton icon="fa fa-edit" hint="Edit" :onClick="iconEdit" />
        </DxColumn>
        <DxPaging :page-size="10" />
        <DxPager
          :visible="true"
          :show-page-size-selector="showPageSizeSelector"
          :allowed-page-sizes="pageSizes"
        />
      </DxDataGrid>

      <!--   Tambah New User   -->
      <b-modal
        v-model="showModalAdd"
        hide-footer
        centered
        scrollable
        size="lg"
        id="add-new-modal"
      >
        <template #modal-header="{ close }" class="text-center">
          <h3>Tambah</h3>
        </template>
        <b-form @submit.stop.prevent="onSubmit">
          <b-form-group
            id="example-input-group-3"
            label-for="input-name"
            label="Nama"
          >
            <b-form-input
              id="input-name"
              name="input-name"
              placeholder="Nama"
              v-model="$v.form.name.$model"
              :state="validateState('name')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback"
              >Nama harus diisi.
            </b-form-invalid-feedback>
          </b-form-group>

          <hr />
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-cancel mr-8"
              type="button"
              @click="onCancel()"
            >
              Kembali
            </button>
            <button type="submit" class="btn btn-add">Simpan</button>
          </div>
        </b-form>
      </b-modal>

      <!--   Edit Existing User   -->
      <b-modal
        v-model="showModalEdit"
        hide-footer
        scrollable
        centered
        size="lg"
        id="edit-user-modal"
      >
        <template #modal-header="{ close }">
          <h3>Edit</h3>
        </template>
        <b-form @submit.stop.prevent="onSubmitEdit">
          <b-form-group id="code-input-group" label="Nama" label-for="name">
            <b-form-input
              id="name"
              name="name"
              placeholder="Nama"
              v-model="formEdit.name"
            ></b-form-input>
          </b-form-group>
          <hr />
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-cancel mr-8"
              type="button"
              @click="resetFormEdit()"
            >
              Kembali
            </button>
            <button
              type="submit"
              :disabled="$v.formEdit.$invalid"
              class="btn btn-add"
            >
              Simpan
            </button>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {
  DxButton,
  DxColumn,
  DxDataGrid,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxSearchPanel
} from "devextreme-vue/data-grid";
import WebService from "../../../core/services/api";
import CustomStore from "devextreme/data/custom_store";
// import EventBus from "../../../core/services/common/EventBus";
import { isNotEmpty } from "../../../core/Apputil/Dx";

const dataGridRefKey = "my-data-grid";

const store = new CustomStore({
  key: "id",
  load: function(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      // "filter",
      "totalSummary",
      "group",
      "groupSummary"
    ].forEach(function(i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    // search by name if exists

    if (
      Array.isArray(loadOptions["filter"]) &&
      loadOptions["filter"].length > 0 &&
      loadOptions["filter"][0] === "name"
    ) {
      params += "&nama=" + loadOptions["filter"][2];
    }
    return WebService.getAllCustomer(params).then(res => {
      return {
        data: res.data.data,
        totalCount: res.data.totalCount,
        summary: res.data.summary,
        groupCount: res.data.groupCount
      };
    });
  }
});

export default {
  mixins: [validationMixin],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DxButton,
    DxSearchPanel,
    DxColumn,
    DxDataGrid,
    DxFilterRow,
    DxPager,
    DxPaging
  },
  data() {
    return {
      form: {
        name: ""
      },
      formEdit: {
        id: null,
        name: ""
      },
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      dataSource: store,
      dataGridRefKey,
      showPageSizeSelector: true,
      pageSizes: [5, 10, 20],
      file1: null,
      file2: null,
      showModalAdd: false,
      showModalEdit: false,
      statuses: [
        {
          value: "true",
          text: "Aktif"
        },
        { value: "false", text: "Tidak Aktif" }
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      columns: ["id", "name", "code"],
      selected: null,
      optionsmodule: [
        { value: null, text: "Select One" },
        { value: "a", text: "Yes" },
        { value: "b", text: "No" },
        { value: "c", text: "--" },
        { value: "d", text: "--" }
      ],
      // array user
      itemsuser: [],
      roles: [],
      fieldsuser: [
        {
          key: "no",
          label: "No.",
          class: "text-center"
        },
        {
          key: "code",
          label: "Code",
          sortable: true,
          class: "text-center"
        },
        {
          key: "name",
          label: "Nama Provinsi",
          sortable: true,
          class: "text-center"
        },
        { key: "actions", label: "Actions" }
      ]
    };
  },
  validations: {
    form: {
      name: {
        required
      }
    },
    formEdit: {
      name: {
        required
      }
    }
  },
  mounted() {
    // this.getDataTable();
  },
  computed: {
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    }
  },
  methods: {
    getDataTable() {
      this.dataGrid.refresh();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateFormEdit(name) {
      const { $dirty, $error } = this.$v.formEdit[name];
      return $dirty ? !$error : null;
    },
    getNumberSequence(index) {
      return index + 1 + this.currentPage * this.perPage - this.perPage;
    },
    onCancel(){
      this.showModalAdd = false;
      this.$v.form.$reset();
      this.$v.formEdit.$reset();
       this.form = {
        id: null,
        name: ""
      };
    },
    hapus(value) {
      const id = value.row.data.id;
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Yakin Data Dihapus?.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "OK",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.deleteCustomer(id)
            .then(data => {
              console.log(data.status);
              if (data.status == 204) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Data berhasil Dihapus",
                  showConfirmButton: false,
                  timer: 1500
                });
                this.resetForm();
                this.getDataTable();
              } else {
                Swal.fire({
                  title: "Kesalahan",
                  text: "Data Gagal Dihapus",
                  icon: "error",
                  showCancelButton: false,
                  confirmButtonText: "Kembali",
                  confirmButtonColor: "#18328D"
                });
              }
              this.resetForm();
              this.getDataTable();
            })
            .catch(error => {
              console.log(error);
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    hideModal() {
      this.$refs["my-modaledit"].hide();
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      let data = {
        name: this.form.name
      };

      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.addCustomer(data)
            .then(data => {
              console.log(data.status);
              if (data.status == 200) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Data berhasil ditambah",
                  showConfirmButton: false,
                  timer: 1500
                });
                this.$v.form.$reset();
                this.resetForm();
                this.getDataTable();
              } else {
                Swal.fire({
                  title: "Kesalahan",
                  text: "Data yang ditambah sudah ada",
                  icon: "error",
                  showCancelButton: false,
                  confirmButtonText: "Kembali",
                  confirmButtonColor: "#18328D"
                });
              }
            })
            .catch(error => {
              console.log(error);
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onSubmitEdit() {
      this.$v.formEdit.$touch();
      if (this.$v.formEdit.$anyError) {
        return;
      }

      let id = this.formEdit.id;
      let data = {
        id: this.formEdit.id,
        name: this.formEdit.name
      };

      console.log(data);

      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.editCustomer(data, id)
            .then(data => {
              if (data.status == 200) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Data Berhasil Dirubah",
                  showConfirmButton: false,
                  timer: 1500
                });
                this.$v.formEdit.$reset();
                this.resetForm();
                this.getDataTable();
              } else {
                Swal.fire({
                  title: "Kesalahan",
                  text: "Data Gagal Dirubah",
                  icon: "error",
                  showCancelButton: false,
                  confirmButtonText: "Kembali",
                  confirmButtonColor: "#18328D"
                });
                this.getDataTable();
              }
              this.showModalEdit = false;
            })
            .catch(error => {
              console.log(error);
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        id: null,
        name: ""
      };
      this.$nextTick(() => {
        this.$bvModal.hide("add-new-modal");
      });
    },
    resetFormEdit() {
      this.formEdit = {
        id: null,
        name: ""
      };

      this.$nextTick(() => {
        this.$bvModal.hide("edit-user-modal");
      });
    },
    iconEdit(e) {
      console.log(e.item);
      this.showModalEdit = true;
      const data = e.row.data;
      this.formEdit.id = data.id;
      this.formEdit.name = data.name;
    }
  }
};
</script>

<style>
.btn-add {
  width: 144px;
  height: 44px;
  background: #c60b09;
  border-radius: 8px;
  color: #fffafa;
}
.btn-cancel {
  width: 144px;
  height: 44px;
  border: 1px solid #c60b09;
  box-sizing: border-box;
  border-radius: 8px;
  color: #c60b09;
}
.title-card-custom {
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: 0.005em;
}
.table-custom {
  border-radius: 15px 15px 15px 15px !important;
}

.thead-block {
  text-align: center;
  background: #d1eefa;
  color: #360302;
  border: none;
}

.thead-block tr th:first-child {
  border-top-left-radius: 15px;
}

.thead-block tr th:last-child {
  border-top-right-radius: 15px;
}

.table-content {
  background: #fafcff;
  text-align: center;
  border-radius: 0 0 15px 15px;
}
</style>
