<template>
  <div>
    <div class="row d-flex ml-2 mt-8">
      <h4>Warehouse</h4>

      <!-- <div class="float-left ml-5 mb-5">
        <b-button variant="danger"
        ><b-icon-upload></b-icon-upload> Export
        </b-button>
        <b-button variant="warning" class="text-dark ml-5"
        ><b-icon-download></b-icon-download> Import
        </b-button>
      </div> -->
      <div class="justify-content-end d-flex ml-5 mb-5">
        <b-button
          variant="danger"
          @click.prevent.stop="onAdd()"
          class="float-left mt- mb-3"
        >
          Add</b-button
        >
      </div>
    </div>
    <div>
      <DxDataGrid
        :ref="dataGridRefKey"
        :data-source="dataSource"
        :columns="columns"
        key-expr="id"
        :show-borders="true"
        :word-wrap-enabled="false"
        :column-auto-width="false"
        :remote-operations="true"
        :column-hiding-enabled="false"
      >
        <DxSearchPanel :visible="true" />
        <DxFilterRow :visible="false" />
        <DxColumn data-field="id" caption="ID" sort-order="desc" :width="70" />
        <DxColumn data-field="code" caption="Code" :allow-filtering="false" />
        <DxColumn
          data-field="name"
          caption="Nama"
          :filter-operations="['contains']"
        />
        <DxColumn :width="100" type="buttons" caption="Aksi">
          <DxButton icon="fa fa-trash" hint="Hapus" :onClick="hapus" />
          <DxButton icon="fa fa-edit" hint="Edit" :onClick="iconEdit" />
        </DxColumn>
        <DxPaging :page-size="10" />
        <DxPager
          :visible="true"
          :show-page-size-selector="showPageSizeSelector"
          :allowed-page-sizes="pageSizes"
        />
      </DxDataGrid>

      <!--   Tambah New User   -->
      <b-modal
        v-model="showModalAdd"
        hide-footer
        centered
        scrollable
        size="lg"
        id="add-new-modal"
      >
        <template #modal-header="{ close }" class="text-center">
          <h3>Tambah</h3>
        </template>
        <b-form @submit.stop.prevent="onSubmit">
          <b-form-group id="example-input-group-1" label-for="example-input-1">
            <template v-slot:label>
              Code<span class="text-danger">*</span>
            </template>
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              placeholder="Code"
              v-model="$v.form.code.$model"
              :state="validateState('code')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback"
              >Code harus diisi.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="example-input-group-3"
            label-for="input-name"
            label="Nama"
          >
            <b-form-input
              id="input-name"
              name="input-name"
              placeholder="Nama Kota"
              v-model="$v.form.name.$model"
              :state="validateState('name')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback"
              >Nama harus diisi.
            </b-form-invalid-feedback>
          </b-form-group>

          <hr />
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-cancel mr-8"
              type="button"
              @click="onCancel()"
            >
              Kembali
            </button>
            <button type="submit" class="btn btn-add">Simpan</button>
          </div>
        </b-form>
      </b-modal>

      <!--   Edit Existing User   -->
      <b-modal
        v-model="showModalEdit"
        hide-footer
        scrollable
        centered
        size="lg"
        id="edit-user-modal"
      >
        <template #modal-header="{ close }">
          <h3>Edit</h3>
        </template>
        <b-form @submit.stop.prevent="onSubmitEdit">
          <b-form-group id="first_name-group" label-for="first_name">
            <template v-slot:label>
              Code<span class="text-danger">*</span>
            </template>
            <b-form-input
              id="code"
              name="code"
              placeholder="Code"
              v-model="$v.formEdit.code.$model"
              :state="validateStateFormEdit('code')"
              aria-describedby="code-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="code-feedback"
              >Nama Depan harus diisi.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="code-input-group" label="Nama" label-for="name">
            <b-form-input
              id="name"
              name="name"
              placeholder="Nama"
              v-model="formEdit.name"
            ></b-form-input>
          </b-form-group>
          <hr />
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-cancel mr-8"
              type="button"
              @click="resetFormEdit()"
            >
              Kembali
            </button>
            <button
              type="submit"
              :disabled="$v.formEdit.$invalid"
              class="btn btn-add"
            >
              Simpan
            </button>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {
  DxButton,
  DxColumn,
  DxDataGrid,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxSearchPanel
} from "devextreme-vue/data-grid";
import WebService from "../../../core/services/api";
// import EventBus from "../../../core/services/common/EventBus";
import CustomStore from "devextreme/data/custom_store";
import { isNotEmpty } from "../../../core/Apputil/Dx";

const dataGridRefKey = "my-data-grid";

const store = new CustomStore({
  key: "id",
  load: function(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      // "filter",
      "totalSummary",
      "group",
      "groupSummary"
    ].forEach(function(i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    // search by name if exists

    if (
      Array.isArray(loadOptions["filter"]) &&
      loadOptions["filter"].length > 0 &&
      loadOptions["filter"][0] === "name"
    ) {
      params += "&nama=" + loadOptions["filter"][2];
    }
    return fetch(process.env.VUE_APP_URL_LOCAL + `/logistik/warehouse${params}`)
      .then(response => response.json())
      .then(data => {
        return {
          data: data.data,
          totalCount: data.totalCount,
          summary: data.summary,
          groupCount: data.groupCount
        };
      })
      .catch(() => {
        throw "Data Loading Error";
      });
  }
});

export default {
  mixins: [validationMixin],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DxButton,
    DxSearchPanel,
    DxColumn,
    DxDataGrid,
    DxFilterRow,
    DxPager,
    DxPaging
  },
  data() {
    return {
      form: {
        code: "",
        name: ""
      },
      formEdit: {
        id: null,
        code: "",
        name: ""
      },
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      dataSource: store,
      dataGridRefKey,
      showPageSizeSelector: true,
      pageSizes: [5, 10, 20],
      columns: ["id", "name", "icon"],
      file1: null,
      file2: null,
      showModalAdd: false,
      showModalEdit: false,
      statuses: [
        {
          value: "true",
          text: "Aktif"
        },
        { value: "false", text: "Tidak Aktif" }
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      selected: null,
      optionsmodule: [
        { value: null, text: "Select One" },
        { value: "a", text: "Yes" },
        { value: "b", text: "No" },
        { value: "c", text: "--" },
        { value: "d", text: "--" }
      ],
      // array user
      itemsuser: [],
      roles: [],
      fieldsuser: [
        {
          key: "no",
          label: "No.",
          class: "text-center"
        },
        {
          key: "code",
          label: "Code",
          sortable: true,
          class: "text-center"
        },
        {
          key: "name",
          label: "Nama Kota/Kab",
          sortable: true,
          class: "text-center"
        },
        { key: "actions", label: "Actions" }
      ]
    };
  },
  validations: {
    form: {
      code: {
        required
      },
      name: {
        required
      }
    },
    formEdit: {
      code: {
        required
      },
      name: {
        required
      }
    }
  },
  mounted() {
    // this.getDataTable();
    // this.getRoles();
  },
  computed: {
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    }
  },
  methods: {
    getRoles() {
      WebService.getProvAll().then(async res => {
        const roles = res.data.data;
        let selectedOptions;
        selectedOptions = [];
        roles.map(i => {
          return selectedOptions.push({
            text: i.name,
            value: i.id
          });
        });
        this.roles = selectedOptions;
        console.log(selectedOptions);
      });
    },
    getDataTable() {
      this.dataGrid.refresh();
    },
    onAdd(){
      this.showModalAdd = true;
      this.$v.form.$reset();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateFormEdit(name) {
      const { $dirty, $error } = this.$v.formEdit[name];
      return $dirty ? !$error : null;
    },
    getLabelCounter() {
      let from = (this.currentPage - 1) * this.perPage + 1;
      let dari =
        this.perPage > this.itemsuser.length
          ? this.currentPage * this.perPage -
            this.perPage +
            this.itemsuser.length
          : this.currentPage * this.perPage;
      return (
        "Shown results " +
        from +
        " - " +
        dari +
        " From " +
        this.totalRows +
        " Data"
      );
    },
    getNumberSequence(index) {
      return index + 1 + this.currentPage * this.perPage - this.perPage;
    },
    hapus(value) {
      const id = value.row.data.id;
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Yakin Data Dihapus?.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "OK",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.deleteLogistik(id)
            .then(data => {
              Swal.fire({
                position: "center",
                icon: data.status,
                title: "Data berhasil Dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetForm();
              this.getDataTable();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Data Gagal Dihapus",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    hideModal() {
      this.$refs["my-modaledit"].hide();
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      let data = {
        name: this.form.name,
        code: this.form.code
      };

      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.addLogistik(data)
            .then(data => {
              Swal.fire({
                position: "center",
                icon: data.status,
                title: "Data berhasil ditambah",
                showConfirmButton: false,
                timer: 1500
              });
              this.$v.form.$reset();
              this.resetForm();
              this.getDataTable();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Data yang ditambah sudah ada",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onCancel(){
      this.showModalAdd = false;
      this.resetForm();
    },
    onSubmitEdit() {
      this.$v.formEdit.$touch();
      if (this.$v.formEdit.$anyError) {
        return;
      }

      let id = this.formEdit.id;
      let data = {
        id: this.formEdit.id,
        code: this.formEdit.code,
        name: this.formEdit.name
      };

      console.log(data);

      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.editLogistik(data, id)
            .then(data => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: data.success,
                showConfirmButton: false,
                timer: 1500
              });
              this.$v.formEdit.$reset();
              this.resetFormEdit();
              this.getDataTable();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal mengubah data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        id: null,
        code: "",
        name: ""
      };
      this.$nextTick(() => {
        this.$bvModal.hide("add-new-modal");
      });
    },
    resetFormEdit() {
      this.formEdit = {
        id: null,
        code: "",
        name: ""
      };

      this.$nextTick(() => {
        this.$bvModal.hide("edit-user-modal");
      });
    },
    iconEdit(e) {
      console.log(e.item);
      this.showModalEdit = true;
      const data = e.row.data;
      this.formEdit.id = data.id;
      this.formEdit.code = data.code;
      this.formEdit.name = data.name;
    }
  }
};
</script>

<style>
.btn-add {
  width: 144px;
  height: 44px;
  background: #c60b09;
  border-radius: 8px;
  color: #fffafa;
}
.btn-cancel {
  width: 144px;
  height: 44px;
  border: 1px solid #c60b09;
  box-sizing: border-box;
  border-radius: 8px;
  color: #c60b09;
}
.title-card-custom {
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: 0.005em;
}
.table-custom {
  border-radius: 15px 15px 15px 15px !important;
}

.thead-block {
  text-align: center;
  background: #d1eefa;
  color: #360302;
  border: none;
}

.thead-block tr th:first-child {
  border-top-left-radius: 15px;
}

.thead-block tr th:last-child {
  border-top-right-radius: 15px;
}

.table-content {
  background: #fafcff;
  text-align: center;
  border-radius: 0 0 15px 15px;
}
</style>
