<template>
    <div>
      <b-card no-body style="border-color: transparent;">
        <b-tabs pills card>
<!--          <b-tab title="Module" active>-->
<!--           <TabModule />-->
<!--          </b-tab>-->

          <b-tab title="Role" active>
            <TabRole />
          </b-tab>

          <b-tab title="User">
           <TabUser />
          </b-tab>

<!--          <b-tab title="User Role">-->
<!--          <TabUserRole />-->
<!--          </b-tab>-->

          <b-tab title="Menu">
            <TabMenu />
          </b-tab>

<!--          <b-tab title="Menu Role">-->
<!--          <TabMenuRole />-->
<!--          </b-tab>-->

          <b-tab title="Dashboard Component">
          <TabCompDS />
          </b-tab>

        </b-tabs>
      </b-card>
    </div>
</template>

<script>
import TabRole from '@/components/cms/tab/TabRole.vue'
import TabUser from '@/components/cms/tab/TabUser.vue'
import TabMenu from '@/components/cms/tab/TabMenu.vue'
import TabCompDS from '@/components/cms/tab/TabCompDS.vue'
export default {
  components: {TabCompDS, TabRole,TabUser,TabMenu },
};
</script>

<style></style>
