<template>
  <div>
    <b-card no-body style="border-color: transparent;">
      <b-tabs pills card>
        <b-tab title="Customer" active>
          <mst_cust />
        </b-tab>
        <b-tab title="Province">
          <mst_prov />
        </b-tab>
        <b-tab title="City">
          <mst_city />
        </b-tab>
        <b-tab title="Warehouse">
          <mst_site />
        </b-tab>
        <b-tab title="Category">
          <mst_cat />
        </b-tab>
        <b-tab title="Sub Category">
          <mst_sub_cat />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import mst_cat from '@/components/cms/tab_master/mst_cat.vue'
import mst_site from '@/components/cms/tab_master/mst_site.vue'
import mst_cust from '@/components/cms/tab_master/mst_cust.vue'
import mst_prov from '@/components/cms/tab_master/mst_prov.vue'
import mst_city from '@/components/cms/tab_master/mst_city.vue'
import mst_sub_cat from '@/components/cms/tab_master/mst_sub_cat.vue'
export default {
  components: {mst_cust,mst_prov, mst_city,mst_site,mst_cat, mst_sub_cat},
};
</script>

<style></style>
