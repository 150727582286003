<template>
  <div class="container-fluid ml-8">
    <b-card no-body style="border-color: transparent">
      <b-tabs pills card>
        <b-tab title="CMS" active>
          <Cms />
        </b-tab>
        <b-tab title="Master">
          <master />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
<!--  <div>-->
<!--    <b-tabs content-class="mt-3" class="bg-white">-->
<!--      <b-tab title="CMS" active no-body class="full-width">-->
<!--        <Cms />-->
<!--      </b-tab>-->
<!--      <b-tab title="Master">-->
<!--        <master />-->
<!--      </b-tab>-->
<!--    </b-tabs>-->
<!--  </div>-->
</template>

<script>
import Cms from "@/components/cms/Cms.vue";
import master from "@/components/cms/master.vue";
export default {
  components: { Cms, master },
};
</script>

<style >
.card-header-tabs {
  margin-right: -21px !important;
  margin-left: -21px !important;
}

.full-width .nav-tabs .nav-item {
  margin-bottom: -1px;
  flex-grow: 1 !important;
  text-align: center !important;
}
</style>
